<template>
  <div>
    <v-tabs v-model="tabs">
      <v-tab>{{$t('users')}}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <UsersApproved/>
      </v-tab-item>

      <v-tab-item>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import UsersApproved from './Approved/Approved.vue'
  export default {
    name: 'Users',
    components: {
      UsersApproved
    },
    data () {
      return {
        tabs: null
      }
    },
    mounted()  {
      this.$store.commit('setDataTitle', this.$i18n.t('users'))
    }
  }
</script>
