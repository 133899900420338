export default {
  name: 'Declarations',
  data () {
    return {
      headers: [
        { text: this.$i18n.t('login'), value: 'username'},
        { text: this.$i18n.t('name'), value: 'first_name'},
        { text: this.$i18n.t('surname'), value: 'last_name'},
        { text: this.$i18n.t('userType'), value: 'role'},
        // { text: 'Перегляд', value: 'event'}
      ],
      items: [],
      tableLoader: false
    }
  },
  mounted () {
    this.getDeclarationReport()
  },
  methods: {
    getDeclarationReport () {
      this.tableLoader = true
      this.$api.get(`user`)
        .then(response => {
        this.tableLoader = false
        if (response.status === 'success') {
          this.items = response.data
          console.log(response);
        }
      })
    },

    openDeclarationInfo (item) {
      this.$router.push({ path: `/declaration/${item.number}` })
    }
  }
}
